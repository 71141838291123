import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Link from "next/link";
import {setPopup} from "../../../store/general";
import {FormattedMessage} from "react-intl";
import {apiUrlWithStore, url} from "../../../helper";
import {getCartDataMount} from "../../../store/cart";
import {FailSvg} from "../../../svg";
import {SocialLogin} from "../../account/SocialLogin";
import {useToast} from "../../../atoms/Toast/useToast";
import {IState} from "../../../types/state";
import {IIndicatorDropDownProps} from "../../../types/dropdown";
import {CustomTextField} from "../../../atoms/CustomTextField";
import cls from "../header.module.scss"

function IndicatoDropDownBody({setOpen, func}: IIndicatorDropDownProps) {

    const history = useRouter();
    const dispatch = useDispatch();
    const toast = useToast();

    const authenticated = useSelector((state: IState) => state.customer.authenticated);
    const customer = useSelector((state: IState) => state.customer);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const cart = useSelector((state: IState) => state.cart);
    const selectedData = useSelector((state: IState) => state.locale.code);

    const [email, SetEmail] = useState<string>();
    const [pass, SetPass] = useState<string>();
    const handlerChange = (event: { target: { value: string } }) => {
        SetEmail(event.target.value);
    };

    const handlerPass = (e: { target: { value: string } }) => {
        SetPass(e.target.value);
    };

    const fetchToLogin = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        let option = {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: pass,
            }),
        };
        // if (checkEmail(email) && pass !== undefined) {
        // @ts-ignore
        fetch(apiUrlWithStore("/api/customer/login?token=true"), option)
            .then((responce) => responce.json())
            .then((res: any) => {
                if (res.token && res.token !== "") {
                    dispatch({type: "AUTHENTICATED", payload: true});
                    dispatch({type: "CUSTOMER_TOKEN", payload: res.token});
                    dispatch({type: "CUSTOMER_ID", payload: res.data.id});
                    dispatch(setPopup(false));
                    history.push("/account/profile/");

                    fetch(apiUrlWithStore(`/api/checkout/cart?token=${res.token}&api_token=${cartToken.cartToken}`))
                        .then((responce) => responce.json())
                        .then((resUser) => {
                            if (resUser) {
                                submitCartData(resUser, res.token);
                            }
                        })
                        .catch((err) => console.error(err));
                } else {
                    //@ts-ignore
                    toast.open(
                        <span className={`d-flex chek-fms`}>
                                <FailSvg/>
                                <FormattedMessage
                                    id="invalid_email_or_password"
                                    defaultMessage={res.error}
                                />
                            </span>
                    )
                }
            });
        // } else {
        //     //@ts-ignore
        //     toast.open(
        //         <span className="d-flex chek-fms">
        //             <FailSvg/>
        //             <FormattedMessage
        //                 id="type_valid_data"
        //                 defaultMessage="Type valid data"
        //             />
        //         </span>
        //     )
        // }
    };

    const submitCartData = (products: any, token: string) => {
        dispatch(getCartDataMount(cartToken.cartToken, { token }, selectedData))
    };

    const checkEmail = (email: string | undefined): string => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email as string)) {
            return "true";
        }
        return "false";
    };

    const dropdown = (
        <div className={`${cls["account-menu"]}`}>
            <form className={`${cls["account-menu__form"]}`}>
                <div className={`${cls["account-menu__form-title"]}`}>
                    <FormattedMessage id="sign_in" defaultMessage="Sign in"/>
                </div>
                <div className={`${cls["account-menu_loginAndpass"]}`}>
                    <CustomTextField
                        value="email"
                        label="E-mail"
                        type="email"
                        handleChange={handlerChange}
                        name="email"
                        inpValue={email}
                    />
                </div>
                <div className={`${cls["account-menu_loginAndpass"]}`}>
                    <CustomTextField
                        value="password"
                        label="Password"
                        type="password"
                        inpValue={pass}
                        handleChange={handlerPass}
                        name="password"
                    />

                    <Link
                        href="/forgot-password"
                        className={`${cls["account-menu__form-forgot-link"]}`}
                    >
                        <a onClick={func}>
                            <FormattedMessage
                                id="forgot_password"
                                defaultMessage="Forgot password?"
                            />
                        </a>
                    </Link>
                </div>
                <div className={`form-group ${cls["account-menu__form-button"]}`}>
                    <button
                        onClick={fetchToLogin}
                        type="submit"
                        className={`btn btn-orange login-drop-btn ${cls["rounded-pills"]}`}
                    >
                        <FormattedMessage id="sign_in" defaultMessage="Sign in"/>
                    </button>
                </div>
                <SocialLogin/>
                <div className={`${cls["account-menu__form-link"]}`}>
                    <span className={`${cls["new-custimer"]}`}>
                        <FormattedMessage
                            id="new_customer"
                            defaultMessage="New Custimer?"
                        />
                    </span>
                    <Link href="/signup">
                        <a onClick={func}>
                            <FormattedMessage
                                id="sign_up"
                                defaultMessage="Create account"
                            />
                        </a>
                    </Link>
                </div>
            </form>
            <div className={`${cls["account-menu__divider"]}`}/>
        </div>
    );

    return <>{dropdown}</>;
}

export default IndicatoDropDownBody;
